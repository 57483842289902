import React from 'react'
import _ from 'lodash'
import { Col, Container, Row } from 'react-grid-system'
import { navigate } from '@reach/router'

const FeaturedBlogCardOne = ({
  date = 'Mar 7, 2022',
  slug,
  title = 'Learn software development a step by step guide',
  author = 'John Doe',
  description = `The problem with today's society is the abundance and instant nature of data. Organization, prioritization and retention of all of this data is near impossible. If you use a complex tool to try to manage these problems it doesn't solve the underlying problems, it creates new ones.`,
  featuredimage = { image: '/', alt: 'blog image' }
}) => {
  return (
    <div
      className="featuredBlogCardOne"
      onClick={() => {
        navigate(`/blog/${(slug || title).toLowerCase()}/`)
      }}
    >
      <Container className="feature___cards">
        <Row>
          <Col md={12} lg={6}>
            <img
              src={featuredimage?.image}
              alt={featuredimage?.alt}
              className="feature__images"
            />
          </Col>
          <Col md={12} lg={6}>
            <p className="featuredBlogCardOne__publishedDate">{date}</p>
            <h2 className="featuredBlogCardOne__title">{title}</h2>
            <p className="featuredBlogCardOne__description">
              {`${description?.substring(0, 150)} ..`}
            </p>
            <p className="featuredBlogCardOne__author">Author : {author}</p>
            <a
              className="featuredBlogCardOne__link"
              href={`/blog/${(slug || title).toLowerCase()}/`}
            >
              Read More
              <span className="blogCardOne__arrow">&#8594;</span>
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default FeaturedBlogCardOne
