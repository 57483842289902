import { graphql } from 'gatsby'
import React, { useState } from 'react'
import BlogCardOne from '../../components/PageComponents/BlogCardOne'
import Header from '../../components/shared/Header'
import { nanoid } from 'nanoid'
import FooterSection from '../../components/PageComponents/FooterSection'
import ThemeChanger from '../../components/shared/ThemeChanger'
import Layout from '../../components/StructureComponents/Layout'
import _, { slice } from 'lodash'
import { Link } from '@reach/router'
import Slider from 'react-slick'
import FaqSecondary from '../../components/shared/FaqSecondary'
/**
 *
 * @param {blogs} object
 */

let carouselSettings = {
  dots: false,
  infinite: true,
  speed: 300,
  autoplaySpeed: 1500,
  pauseOnHover: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}

const SingleBlog = (props) => {
  const headings = props.data.blog.headings

  const checkIfTwoArrayshaveSameElement = (arr1, arr2) =>
    arr1.some((val) => arr2.includes(val))

  const blogData = props?.data?.blog?.frontmatter

  const filteredBlogs = props.data.blogs.edges.filter((blog) => {
    return (
      checkIfTwoArrayshaveSameElement(
        blogData.categories,
        blog.node.frontmatter.categories
      ) &&
      _.kebabCase(blog.node.frontmatter.title) !== _.kebabCase(blogData.title)
    )
  })
  const [blogState, setBlogState] = useState(filteredBlogs)

  const renderTableOfContent = (headings) => {
    const handleMakeActive = (e) => {
      const current = e.target
      document
        .querySelectorAll('.links')
        .forEach((item) => item.classList.remove('active'))
      current.classList.add('active')
    }
  }
  const renderbreadcrumb = (title) => {
    return (
      <div className="breadcrumb-wrap">
        <Link to="/" className="parent-page">
          Home
        </Link>
        {'>'}
        <Link to="/blog/" className="parent-page">
          Blog
        </Link>
        {'>'}
        <span className="current-page">{title}</span>
      </div>
    )
  }

  const metaToShow = {
    ...props?.data?.blog?.frontmatter?.meta,
    canonicalLink: props?.data?.blog?.frontmatter?.meta?.canonicalLink
      ? props?.data?.blog?.frontmatter?.meta?.canonicalLink
      : `/blog/${props?.data?.blog?.frontmatter?.slug}` || '/'
  }

  return (
    <>
      <Layout
        meta={metaToShow}
        alternateOgImage={blogData?.featuredimage?.image}
      >
        <div className="single__page_two">
          <Header />
          <ThemeChanger />

          <div className="maxWidthcontainer">
            <main className="wrapper__mainside">
              <div className="wraper__single__page">
                <div className="left__side_main">
                  {renderbreadcrumb(blogData.title)}
                  <h1 className="main_heading">{blogData.title}</h1>
                  <div className="Date__author__tags">
                    <div className="Date">{blogData.date}</div>
                    <div className="author">
                      <a
                        href={`/author/${_.kebabCase(blogData.author)}`}
                        className="name"
                      >
                        {blogData.author}
                      </a>
                    </div>
                    <div className="tags">
                      {blogData.categories.map((category) => (
                        <button className="tag-btn" key={nanoid()}>
                          <a
                            href={`/category/${_.kebabCase(category)}/`}
                            className="tag"
                          >
                            {category}
                          </a>
                        </button>
                      ))}
                    </div>
                  </div>
                  <div className="main__img">
                    <img
                      src={blogData.featuredimage?.image}
                      alt={blogData.featuredimage?.alt}
                    />
                  </div>

                  <div
                    className="righ__side__desc"
                    dangerouslySetInnerHTML={{
                      __html: props?.data?.blog?.html
                    }}
                  />

                  {/* // Similar Blogs Section  */}
                  <div className="similarBlogsCarousal">
                    <h2 className="similarBlogsCarousal__title">
                      Similar Blogs
                    </h2>
                    <Slider {...carouselSettings}>
                      {blogState?.slice(0, 4)?.map((blog) => (
                        <div className="similarBlogsCarousal__itemWrap">
                          <BlogCardOne
                            {...blog.node.frontmatter}
                            html={blog.node.html}
                          />
                        </div>
                      ))}
                    </Slider>
                  </div>
                  {/* Similar Blogs Section  */}
                </div>
              </div>
            </main>
          </div>

          {props?.data?.blog?.frontmatter?.faq && (
            <FaqSecondary faqs={props?.data?.blog?.frontmatter?.faq} />
          )}

          <FooterSection
            {...props?.data?.indexPage?.frontmatter?.footersection}
          />
        </div>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  ## Query for SinglePost data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query SingleBlog($id: String!) {
    indexPage: markdownRemark(frontmatter: { template: { eq: "HomePage" } }) {
      frontmatter {
        footersection {
          primaryHeading
          description
          secondaryheading
          tertiaryheading
          footerList {
            primaryheading
            footerLink
          }
        }
      }
    }

    blogs: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "blogs" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            slug
            date(formatString: "MMM D YYYY")
            categories
            status
            author
            description
            featuredimage {
              image
              alt
            }
          }
        }
      }
    }
    blog: markdownRemark(id: { eq: $id }) {
      id
      headings {
        value
        depth
      }
      html
      frontmatter {
        meta {
          title
          description
          ogimage
          canonicalLink
        }

        faq {
          question
          answer
        }

        categories
        title
        slug
        date(formatString: "MMM D YYYY")

        author
        featuredimage {
          image
          alt
        }
      }
      frontmatter {
        title
      }
    }
    #END OF Query
  }
`

export default SingleBlog
